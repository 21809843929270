import React from "react"
import { graphql, Link } from "gatsby"
import styled, { css } from "styled-components"
import ScrollToTop from "react-scroll-to-top"
import BalanceText from "react-balance-text"
import { RefTagger } from "react-reftagger"
import {
  FootnotesProvider,
  FootnoteRef as Ref,
  Footnotes,
} from "react-a11y-footnotes"
import { SEO, Layout, PortableText, Share, Figure } from "../components/index"

import "react-a11y-footnotes/dist/styles.css"

// Declaring query here allows us to shadow components
export const query = graphql`
  query ($slug: String!) {
    post: sanityPosts(slug: { current: { eq: $slug } }) {
      ...post
    }

    heading: sanityPosts(
      slug: { current: { eq: $slug } }
      sections: {
        elemMatch: { content: { elemMatch: { style: { eq: "h3" } } } }
      }
    ) {
      sections {
        content {
          style
          children {
            text
          }
        }
      }
    }

    sanityPages(templateKey: { eq: "blog" }) {
      title
      slug {
        current
      }
    }

    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`

const NewsTemplate = ({ location, data }) => {
  let parent = data.sanityPages
  let { title, author, category, publishedAt, slug, mainImage } = data.post
  let altText = mainImage.asset.altText
  let canonicalUrl = data.site.siteMetadata.siteUrl + location.pathname
  let heading

  if (data.heading && data.heading.sections[0].content) {
    heading = data.heading.sections[0].content
  }

  return (
    <Layout>
      <RefTagger
        bibleVersion={"NKJV"}
        socialSharing={""}
        caseInsensitive={true}
      />

      <StyledBody>
        {/*<Breadcrumbs
          parent={{ title: parent.title, link: parent.slug.current }}
  current={{ title: title, link: slug.current }}
        />*/}
        {title && (
          <StyledHeading>
            <BalanceText>{title}</BalanceText>
          </StyledHeading>
        )}
        {author && (
          <StyledDetails>
            <Link to={`/authors/${author.slug.current}`} title={author.name}>
              {author.name}
            </Link>
            {publishedAt && (
              <span style={{ fontStyle: "italic" }}> — {publishedAt}</span>
            )}
          </StyledDetails>
        )}
        <Share>
          <Share.Email title={title} author={author.name} url={canonicalUrl} />
          <Share.Print />
          <Share.Twitter
            title={title}
            author={author.name}
            url={canonicalUrl}
          />
          <Share.Facebook url={canonicalUrl} />
        </Share>

        {mainImage && <Figure node={mainImage} attribution={altText} />}

        {category && (
          <StyledCategories>
            {category.map((cat) => (
              <Link
                key={cat.name}
                to={`/categories/${cat.slug.current}`}
                title={cat.name}
              >
                {cat.name}
              </Link>
            ))}
          </StyledCategories>
        )}

        {heading && (
          <StyledSections>
            <span>In this article</span>
            {heading
              .filter((item) => item.style === "h3")
              .map((filteredHeading) => (
                <a
                  href={"#" + filteredHeading.children[0].text}
                  key={filteredHeading.children[0].text}
                >
                  {filteredHeading.children[0].text}
                </a>
              ))}
            <a href={"#footnotes-label"} key="Footnotes">
              Footnotes
            </a>
          </StyledSections>
        )}

        <FootnotesProvider>
          <PortableText blocks={data.post._rawSections} />
          <Footnotes Wrapper={"div"} />
        </FootnotesProvider>
      </StyledBody>

      <ScrollToTop top={1200} smooth />
    </Layout>
  )
}

export default NewsTemplate

export function Head({ data }) {
  let { title, description } = data.post

  return <SEO title={title} description={description} />
}

const bodyStyle = ({ theme }) => css`
  margin: 0 auto;
  max-width: ${theme.maxWidth["md"]};
  word-break: break-word;

  ${theme.breakpoints["lg"]`
  padding: 0 ${theme.spaces[4]};
  `}

  ${theme.breakpoints["xl"]`
  padding: 0 ${theme.spaces[8]};
  `}

  .scroll-to-top {
    bottom: ${({ theme }) => theme.spaces[3]};
    right: ${({ theme }) => theme.spaces[3]};

    ${({ theme }) => theme.breakpoints["sm"]`
      bottom: ${({ theme }) => theme.spaces[4]};
      right: ${({ theme }) => theme.spaces[4]};
    `}

    ${({ theme }) => theme.breakpoints["md"]`
      bottom: ${({ theme }) => theme.spaces[7]};
      right: ${({ theme }) => theme.spaces[7]};
    `}

    ${({ theme }) => theme.breakpoints["lg"]`
      bottom: ${({ theme }) => theme.spaces[14]};
      right: ${({ theme }) => theme.spaces[8]};
    `}
}

  p, ul {
    ${({ theme }) => theme.breakpoints["xs"]`
    font-size: ${({ theme }) => theme.fontSize["xb"]};
   `}

   ${({ theme }) => theme.breakpoints["lg"]`
    font-size: ${({ theme }) => theme.fontSize["md"]};
   `}
  }

  .bodyLink, .rtBibleRef {
  border-bottom: .11em dashed ${({ theme }) => theme.colours["grey3"]};

  &:hover {
    color: ${({ theme }) => theme.colours["primary"]};
    border-color: ${({ theme }) => theme.colours["primary"]};
  }
 }
 
 blockquote {
   margin: 1em 0;
   font-style: italic;
   padding-left: 1em;

   ${({ theme }) => theme.breakpoints["xs"]`
    font-size: ${({ theme }) => theme.fontSize["xb"]};
    padding-left: 1.5em;
   `}

    ${({ theme }) => theme.breakpoints["sm"]`
    font-size: ${({ theme }) => theme.fontSize["md"]};
   `}

 }

 .rtTooltipBody {
   font-weight: 400;

   .verse-ref {
     display: none;
   }
 }

 .dropCap {
  color: #903;
  float: left;
  font-size: 5.5em;
  padding: 0.15em .1em 0 0;
  line-height: .65;
}
}`

const StyledBody = styled.div([bodyStyle])

const categoriesStyle = ({ theme }) => css`
  display: inline-block;
  font-size: ${theme.fontSize["2xs"]};
  margin: ${theme.spaces[2]} 0;

  ${theme.breakpoints["lg"]`
  margin: ${theme.spaces[3]} 0;
  `}

  a {
    color: ${theme.colours["grey2"]};
    display: inline-block;
    border: 1px solid transparent;
    border-radius: 18px;
    background: ${theme.colours["grey5"]};
    margin-right: ${theme.spaces[1]};
    padding: ${theme.spaces[1]} ${theme.spaces[3]};

    &:last-of-type {
      margin-right: 0;
    }
  }
`

const StyledCategories = styled.div([categoriesStyle])

const sectionsStyle = ({ theme }) => css`
  margin-bottom: ${theme.spaces[3]};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: ${theme.spaces[6]};
  background: ${theme.colours["grey6"]};
  border: 1px solid ${theme.colours["grey4"]};

  span {
    font-weight: 500;
    font-size: ${theme.fontSize["xb"]};
    margin-bottom: ${theme.spaces[1]};

    ${theme.breakpoints["lg"]`
      font-size: ${theme.fontSize["md"]};
    `}
  }

  a {
    display: inline-block;
    border-bottom: 0.11em dashed ${({ theme }) => theme.colours["grey3"]};
    margin-bottom: ${theme.spaces[1]};

    ${theme.breakpoints["lg"]`
      font-size: ${theme.fontSize["xb"]};
    `}
  }

  ${theme.breakpoints["lg"]`
    padding: ${theme.spaces[7]} ${theme.spaces[9]};
    margin-bottom: ${theme.spaces[5]};
  `}
`

const StyledSections = styled.div([sectionsStyle])

const headingStyle = ({ theme }) => css`
  text-align: center;
  margin-top: 10vh;

  ${theme.breakpoints["md"]`
    margin-top: 20vh;
  `}
`

const StyledHeading = styled.h1([headingStyle])

const detailsStyle = ({ theme }) => css`
  text-align: center;
  margin: 2.5vh 0 5vh 0;

  span {
    font-style: italic;
  }

  ${theme.breakpoints["md"]`
    margin: 5vh 0 10vh 0;
  `}
`

const StyledDetails = styled.div([detailsStyle])
